import { For } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { Heading, Container, Page, Section } from '@troon/ui';
import { createAsync, useNavigate } from '@solidjs/router';
import { Hero } from '../../components/hero/photo';
import { SearchLocations } from '../../components/search-locations';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { PopularDestinationFullBleed } from '../(home)/_variants/_components/popular-destination';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function CourseDirectorty(props: RouteSectionProps) {
	const navigate = useNavigate();

	const data = createAsync(() => getFacilities({}), { deferStream: true });

	return (
		<>
			<Title>Explore Golf Courses | Troon</Title>
			<Meta
				name="description"
				content="We provide services to 900+ locations while also managing various amenities, such as racquet sports, aquatics, fitness, food & beverage, lodging and more."
			/>
			<Hero
				src="https://images.ctfassets.net/rdsy7xf5c8dt/6wmpgfIscFhZsfeIHC5AnV/286134ac72dc54a514fa0a517dc482e1/40df0b57edf404f46234faa4823ca852.jpg"
				fullWidth
			>
				<Container class="items-start text-left">
					<div class="flex max-w-3xl flex-col gap-8">
						<Heading as="h1">Explore Troon</Heading>
						<p>
							We provide services to 900+ locations while also managing various amenities, such as racquet sports,
							aquatics, fitness, food & beverage, lodging and more.
						</p>
						<div class="w-full rounded bg-white text-neutral-900">
							<SearchLocations
								onSelectPlace={(place) => {
									const params = new URLSearchParams({
										lat: place.coordinates.latitude.toString(),
										lon: place.coordinates.longitude.toString(),
										query: place.displayValue ?? place.name,
									});
									navigate(`/courses/search?${params.toString()}`);
								}}
								onSelectRegion={(region) => {
									const params = new URLSearchParams({
										regionId: region.id,
										query: region.displayValue ?? region.name,
									});
									navigate(`/courses/search?${params.toString()}`);
								}}
								inputClass="ps-10 py-4"
								mobileInputClass="p-4"
								placeholder="Search by city or course name"
							/>
						</div>
					</div>
				</Container>
			</Hero>
			<Container>
				<Page>
					<Section>
						<Heading as="h2">Popular Destinations</Heading>

						<ul class="grid grid-cols-2 gap-4 lg:grid-cols-4">
							<For each={data.latest?.featuredDestinations}>
								{(dest) => (
									<li>
										<PopularDestinationFullBleed {...dest} />
									</li>
								)}
							</For>
						</ul>
					</Section>
					<Section>
						<Heading as="h2">Explore by Region</Heading>
						{props.children}
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const query = gql(`query courseDirectoryPopular {
	featuredDestinations {
		... on DestinationRegion {
			...PopularRegion
		}
		... on DestinationPlace {
			...PopularPlace
		}
	}
}`);

const getFacilities = cachedQuery(query);
